.product-container{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4rem;
}

.vegLogo{
  width: 2.3rem;
  height: 2.3rem;
}

.vegLogo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-name{
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 960px){
  .product-container{
    flex-direction: column;
    align-items: center;
  }
}

.product-container-left{
  position: sticky;
  top: 8rem;
  z-index: 1;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
}

.product-image{
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bababa;
  border-radius: .5rem;
}

.product-image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all .2s ease-in-out;
}


.prev-button,
.next-button {
  background-color: #fff;
  color: var(--harvest-gold);
  border: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.prev-button:hover,
.next-button:hover {
  color: var(--dark-green);
}

.prev-button{
  margin-right: .5rem;
}

.next-button{
  margin-left: .5rem;
}

.prev-button span,
.next-button span 
{
  font-size: 4rem;
  font-weight: bold;
}

.product-image-container{
  display: flex;
}


.thumbnail-gallery{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-right: 1rem;
}

.thumbnail{
  width: 4.2rem;
  height: 4.2rem;
  border: 1px solid #bababa;
  border-radius: .5rem;
  cursor: pointer;
  transition: all .1s ease-in-out;
}

.thumbnail img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumbnail:hover{
  border: 2px solid var(--dark-green);
}

.thumbnail.active{
  border: 2px solid var(--dark-green);
}

@media (max-width: 960px) {
  .product-image-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .thumbnail-gallery {
    padding-right: 0rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
  }

  .thumbnail {
    width: 5rem;
    height: 5rem;
  }

  .product-container-left {
    position: relative;
    top: 0rem;
  }
}

.product-container-right{
  width: 100%;
}

.product-punchline{
  color: #9c9c9c;
}

.product-price{
  color: var(--dark-green);
}

.product-quantity{
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #9c9c9c;
}

.product-quantity-value{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  border: 2px solid var(--kelly-green);
  border-radius: .5rem;
  text-align: center;
}

.product-highlight{
  display: flex;
  color: #9c9c9c;
  gap: 1rem;
}

.product-highlight-value-item{
  display : flex;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.product-available{
  color : #9c9c9c;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-available-value{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: .5rem;
  padding: .5rem 1rem;
  border: 2px solid var(--harvest-gold);
  border-radius: .5rem;
}

.product-buy{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-buy-value{
  display : grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.product-specification{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-specification-value{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top : .3rem;
  padding-left: 0rem;
}

.product-specification-value-item{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.product-specification-value-item:last-child{
  border-bottom: none;
}

.product-ingredient{
  display: flex;
  gap: 1rem;
}

.product-ingredient-value-item{
  display: flex;
  gap: .5rem;
  padding-top : .3rem;
}

.product-nutritional{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-nutritional-value{
  display : grid;
  grid-template-columns: repeat(3, 1fr);
}

.product-nutritional-value-item{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-top : .3rem;
  padding-left: 0rem;
}

/*product-nutritional-value-item-value except first child align text right*/
.product-nutritional-value-item:not(:first-child) {
  text-align: right;
}

.product-nutritional-value-item-value{
  border-bottom: 1px solid #e0e0e0;
}

.product-nutritional-value-item-value:last-child{
  border-bottom: none;
}

.product-legal-disclaimer{
  display: none;
}

.goBack{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 2px solid #e0e0e0;
}