.navbar{
  width: 100%;
  /* height: 5.5rem; 1 + 2.5 + 2 */
  /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3); */
  position: fixed;
  z-index: 5;
}

.navbar-notification{
  padding: .15rem 0;
  text-align: center;

  /* display: none; */
}

.navbar-main{
  padding: .3rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #00000021;
}

.navbar-logo{
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
}

.navbar-logo img{
  height: 100%;
}

.navbar-hamburger{
  display: none;
  cursor: pointer;
}


.search-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 23rem;
  height: 2.3rem;
}

.search-bar input{
  display: flex;
  width: 20rem;
  height: 2.3rem;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  align-items: center;
  gap: .5rem;
  border-radius: 100px;
  background-color: #fff;
}

.search-button {
  border-radius: 100px;
  height: 100%;
  width: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-button img{
  height: 40%;
  /* width: 40%; */
}

.navbar-nav-links{
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.navbar-nav-links-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: .1rem 12rem;
}

.navbar-nav-links-item{
  cursor: pointer;
}

.link-active{
  color: var(--kelly-green);
}

/*780px make this latter*/
@media(max-width: 960px) {
  .navbar-nav-links{
    position: absolute;
    right: 0;
    width: 0px;
    background-color: #fff;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
  }

  .navbar-nav-links-container{
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .navbar-nav-links.active{
    width: 100%;
  }

  .navbar-nav-links-item{
    width: 100%;
    border-bottom: 1px solid #00000021;
    padding: .25rem 1rem;
  }

  .navbar-nav-links-item p{
    font-size: 2rem;
  }

  .navbar-button{
    display: none;
  }

  .search-bar{
    width: 17.6rem;
  }

  .search-bar input{
    width: 15rem;
  }
  
  .navbar-hamburger{
    display: block;
  }

  .navbar-main{
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    padding: .8rem 1rem;
  }
}