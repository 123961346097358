.product-description-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.product-description-container{
  padding: 0 4rem;
}


.product-description-image{
  height: 18rem;
  width: 18rem;
}

.product-description-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}


@media (max-width: 640px){
  .product-description-grid{
    display: block;
  }
  .product-description-image-container{
    padding: 4rem;
    display: flex;
    justify-content: center;
  }
}

.shop-type-button-container{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  gap: 2rem;
}

.shop-type-link{
  text-decoration: none;
  color: var(--kelly-green);
  padding: 1rem 2rem;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 10px rgba(0,0,0,0.5);
}

.shop-type-link:hover{
  box-shadow: 0 10px 15px rgba(0,0,0,0.6);
}

.shop-type-button-container .active{
  color: var(--harvest-gold);
  box-shadow: 0 10px 15px rgba(0,0,0,0.6);
}

.product-card-container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 2rem 0;
}

@media (max-width: 960px){
  .product-card-container{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 640px){
  .product-card-container{
    grid-template-columns: repeat(2, 1fr);
  }
}

.product-card{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 10px rgba(0,0,0,0.5);

}

.product-card:hover{
  box-shadow: 0 10px 15px rgba(0,0,0,0.6);
}

.product-card-img-container{
  height: 15rem;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.product-card-img-container img{
  width: 80%;
  height: 100%;
  object-fit: cover;
}

.product-card-price-button{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: .5rem;
}

.know-more-button,
.buy-btn
{
  text-decoration: none;
  background-color: var(--kelly-green);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: .5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.know-more-button:hover,
.buy-btn:hover
{
  background-color: var(--harvest-gold);
}