.banner{
    padding-top: 3rem ;
}

.banner-container{
    display: flex;
    height: 95vh;
}

.banner-grid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.left-item {
    display: flex;
    align-items: center;
}

.right-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner-sample-container {
    border-radius: 1rem;
    width: 17rem;
    height: 22rem;

}


.banner-image-container{
    background: transparent;
}

.banner-image-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

@media (max-width: 640px){
    .banner-grid-container{
        grid-template-columns: 1fr;
        grid-gap: 0rem;
    }
    .left-item{
        align-items: flex-start;
        order: 2;
    }
    .right-item{
        order: 1;
    }
}

.banner-title{
    margin-top: 2rem;
}

.banner-sub-title{
    margin-top: 2rem;
}

/*ourPromise*/
.ourPromise{
    padding: 4rem 0;
}
.ourPromise-grid-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    padding: 4rem 1rem;
}

.ourPromise-grid-item{
    display: flex;
    align-items: flex-start;
    gap: 1rem
}

.ourPromise-icon{
    border-radius: 50px;
    height: 4rem;
    width: 4rem;
}

.Promise-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}


@media (max-width: 960px) {
    .ourPromise-grid-container {
        grid-template-columns: 1fr;
    }
}



/*Home Product Show Component*/
.homePS{
    padding: 6rem 0;
}


.PS-carousel-grid-container{
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* height: 26rem; */
    margin-top: 2rem;
    grid-gap: 1rem;
    padding: 0 1rem;
}

.PS-intro{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 15px;
}

.PS-product-grid-container{
    overflow: hidden;
}

.PS-product-container{
    height: 100%;

}

.carrousel{
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    scroll-behavior: auto;
    gap: 1.2rem;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    padding: 1rem;
}

.carrousel::-webkit-scrollbar {
    display: none;
}

.carrousel a{
    border-radius: 15px;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.card-product-image{
    height: 18rem;
    width: 16rem;
    border-radius: .5rem .5rem 0 0;
    overflow: hidden;
    margin: .3rem;
    display: flex;
    justify-content: center;
}

.card-product-image img{
    height: 100%;
    width: 80%;
    object-fit: cover;
}

@media (max-width: 760px) {
    .PS-carousel-grid-container {
        grid-template-columns: 1fr;
    }

    .cap{
        padding: 1rem;
    }
}


/*Customer Testimonial*/
.CT-intro{
    padding-top: 8rem;
    padding-bottom: 4rem;
}
