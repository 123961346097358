.about-us-container{
  padding: 8rem 0;
}

.about-intro{
  text-align: center;
  margin: 3rem 0;
}

.about-desc{
  margin: 3rem 0;
}