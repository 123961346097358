@import url('https://fonts.googleapis.com/css2?family=Quattrocento:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;700&display=swap');

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}